
<div class="logo-container">
  <div>
    <img src="{{ img }} "    alt=""   height="60px"  width="200px" 
    >
</div>
</div>

<h1 id="title" class="title">サインイン</h1>


<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>ログイン失敗</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let fail of failed" class="alert-message">{{ failed }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>ログイン成功</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let succes of success" class="alert-message">{{ success }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">電子メールアドレス:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.email"
           #email="ngModel"
           name="email"
           id="input-email"
           pattern=".+@.+\..+"
           placeholder="電子メールアドレス"
           autofocus
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.email.required')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        メールは必須です！
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        有効な電子メールアドレスを入力してください。
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-password">パスワード：</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.password"
           #password="ngModel"
           name="password"
           type="password"
           id="input-password"
           placeholder="パスワード"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        パスワードが必要です！
      </p>
      <p class="caption status-danger" *ngIf="(password.errors?.minlength || password.errors?.maxlength)">
        パスワードは
         {{ getConfigValue('forms.validation.password.minLength') }}文字以上～
         {{ getConfigValue('forms.validation.password.maxLength') }} 文字以内
        （記号可）です。
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">次回から入力を省略</nb-checkbox>
    <!--
    <a class="forgot-password" routerLink="../request-password">Forgot Password?</a>
    -->
  </div>

  <button nbButton
          fullWidth
          status="success"
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
          サインイン
  </button>
</form>
